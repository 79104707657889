import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import LanguageSelector from '../language_selector/language_selector';
import configData from '../../config.json';
import Toggle from 'react-toggle';
import Loader from 'react-loader-spinner';
import './profile-main.scss';

const Profile = ({
  loadPreferencesData,
  toggleSmsReminder,
  toggleuseRTL,
  useRTL,
  userId,
  preferences,
  isValidPhoneNumber,
  ...props
}) => {
  const liteMode = configData.liteMode;

  const [webappDirection, setWebappDirection] = useState(localStorage.getItem('webappDirection') === 'rtl');

  useEffect(() => {
    loadPreferencesData(userId);
  }, [loadPreferencesData, userId]);

  useEffect(() => {
    setWebappDirection(useRTL)
  }, [useRTL])

  const smsReminderHandler = (e) => {
    toggleSmsReminder(userId, e.target.checked);
  };
  // /**
  //  * Handler to change the webapp direction from LTR to RTL.
  //  * @param {*} e 
  //  */
  const rtlDisplayHandler = (e) => {
    if (e.target.checked) {
      localStorage.setItem('webappDirection', 'rtl');
      toggleuseRTL(userId, true)
    } else {
      localStorage.removeItem('webappDirection');
      toggleuseRTL(userId, false)
    }
    setWebappDirection(e.target.checked);
  };

  return (
    <div className="profile-main">
      {preferences?.loadingPreferences && (
        <div className="loading">
          <Loader type="TailSpin" color="#61C7B9" height="100" width="100" />
        </div>
      )}

      {!liteMode && (
        <div className="profile-element-container" style={{ marginTop: '10px' }}>
          <div className="profile-option-container">
            <p className="profile-language">{props.strings['Change Language']}</p>

            <LanguageSelector isLogged formSelect />
          </div>
        </div>
      )}
      <div className="profile-option-container">
        <label className="sms-reminder" htmlFor="sms-reminder">
          {props.strings['Allow my coach to send me messages']}
          {!isValidPhoneNumber && (
            <em className="profile-option-container__em">
              {
                props.strings[
                'Your phone number is not a valid number, your coach will not be able to send you messages.'
                ]
              }
            </em>
          )}
        </label>
        <Toggle
          className="sms-reminder-toggle"
          id="sms-reminder"
          checked={preferences.preferencesData.receiveSmsReminders}
          disabled={!isValidPhoneNumber}
          onChange={(e) => smsReminderHandler(e)}
        />


      </div>
      {preferences.preferencesData.specialFeatures?.includes("preferRtlDisplay") && (

        <div className="profile-option-container">
          <label className="rtl-display" htmlFor="rtl-display">
            {props.strings['Display Right-to-Left']}
          </label>
          <Toggle
            className="rtl-display-toggle"
            id="rtl-display"
            checked={webappDirection}
            onChange={(e) => rtlDisplayHandler(e)}
          />
        </div>
      )}


      <div className="profile-main__footer">
        <dl>
          <dt>{props.strings['About Cell-Ed']}</dt>
          <dd>
            <a
              className="faqs__link"
              href="https://www.cell-ed.com/privacy-policy"
              target="_blank"
              rel="noreferrer">
              {props.strings['Terms of Service and Privacy Policy']}
            </a>
          </dd>
          {props.versionTag && props.versionTag.length > 0 && (
            <dd>
              <p className="profile-main__version-number">
                {props.strings['App version']} {props.versionTag}
              </p>
            </dd>
          )}
        </dl>
      </div>
    </div>
  );
};

Profile.defaultProps = {
  strings: {
    'Change Language': 'Change Language',
    'Display Right-to-Left': 'Display Right-to-Left',
    'Allow my coach to send me messages': 'Allow my coach to send me messages',
    'Your phone number is not a valid number, your coach will not be able to send you messages.':
      'Your phone number is not a valid number, your coach will not be able to send you messages.',
    'Terms of Service and Privacy Policy': 'Terms of Service and Privacy Policy',
    'About Cell-Ed': 'About Cell-Ed',
    'App version': 'App version'
  },
  versionTag: ''
};

Profile.propTypes = {
  loadPreferencesData: PropTypes.func.isRequired,
  toggleSmsReminder: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
  isValidPhoneNumber: PropTypes.bool.isRequired,
  versionTag: PropTypes.string
};

export default Profile;
